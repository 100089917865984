<template>
    <div class="addFollow" >
<!--        <van-nav-bar title="添加跟进" fixed left-arrow @click-left="leftReturn"/>-->
<!--        <rx-navBar title="跟进" returnPage="addPotentialHouse" :params="follow"></rx-navBar>-->
        <rxNavBar title="添加跟进"></rxNavBar>
        <div class="formDiv">
            <div class="firstLine">
                <div class=circle1 id="circle"></div>
                <div class="fangshi" >跟进方式</div>
<!--                点击下拉-->
                <div class="selectDiv" @click="optionsShow = !optionsShow" >
                    <span class="selectdevide">|</span>
                    <span :class="[this.wayinfo.dictionaryTitle!='请选择'?'selecy-followwayBlack':'selecy-followway']">
                        {{wayinfo.dictionaryTitle}}
                    </span>
                    <div class="arrows"></div>
                </div>
            </div>
            <div class="secondLine">
                <div class="followDetailTwo">
                    <div :class="[followContent==''?'circle2':'redcircle2']" ></div>
                     <div class="neirong" ><span>跟进内容</span></div>
                </div>
                <div class="questionDiv">
                    <textarea class="questionText" v-model="followContent">

                    </textarea>
                </div>
            </div>
            <button :class="[(followContent&&(this.wayinfo.dictionaryTitle !='请选择'))==''?'savebuttongrew':'savebutton']"
                    :disabled="!wayinfo.dictionaryTitl=='请选择'||!followContent||disabledFalg" v-on:click="add">保存</button>
        </div>
        <div class="followRecord">
            跟进记录
        </div>
        <div class="approve">
            <div class="time_line">
                <div class="item" v-for="(item,index) in orders" :key="index">
                    <div class="left">
                        <div :class="{line:true ,line_top:true,first_top:index==0}"></div>
                        <div class="point"></div>
                        <div :class="{line:true, line_bottom :true,lastLine:index==orders.length-1}"></div>
                    </div>
                    <div class="right">
                        <div class="genjinOne">
                            <div class="followInfo">
                                <div class="followInfoDiv">
<!--                                    <div class="followType">{{item.dictionaryTitle}}</div>-->
                                    <p class="followType">{{item.dictionaryTitle}}</p>
                                    <div class="name">{{item.staffName}}</div>
                                    <div class="time">{{item.addTime}}</div>
                                </div>
                                <div class="followDetail">
                                   <span> {{item.followUpDetail}} </span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

        </div>
        </div>
      <van-popup v-model="optionsShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="waysArr"
            @cancel="optionsShow = false"
            :default-index="waysIndex"
            value-key="dictionaryTitle"
            @confirm="selectoneway"
        />
      </van-popup>
    </div>
</template>
<style scoped lang="less">
    @redius: 8px;
    @spanColor: rgba(102, 102, 102, 1);
    .approve {
        /*margin-top: 12px;*/
        float: left;
        margin-bottom: 20px;
        .time_line {
            .item {
                display: flex;
                align-items: stretch;
                justify-content: space-between;

                .left {
                    /*margin-top: -35px;*/
                    width: 20px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    /*justify-content: center;*/
                    align-items: center;
                    margin-left: 15px;
                    /*margin-right: 5px;*/

                    .line {
                        width: 1px;
                        background-color:#3191FF;
                        /*position: absolute;*/
                        &.line_top {
                            top: 0px;
                            /*<!--height: ceil(50%-8px);-->*/
                            height: 35px;
                            &.first_top {
                                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), #3191FF );
                                /* Safari 5.1 - 6.0 */
                                background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), #3191FF );
                                /* Opera 11.1 - 12.0 */
                                background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1),#3191FF );
                                /* Firefox 3.6 - 15 */
                                background: linear-gradient(to bottom, rgba(255, 255, 255, 1), #3191FF );
                            }
                        }

                        &.line_bottom {
                            bottom: 0px;
                            height: ceil(100%-20px);
                            /*height: 100%;*/
                        }
                        &.lastLine{
                            background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 1), #3191FF );
                            /* Safari 5.1 - 6.0 */
                            background: -o-linear-gradient(top, rgba(255, 255, 255, 1), #3191FF );
                            /* Opera 11.1 - 12.0 */
                            background: -moz-linear-gradient(top, rgba(255, 255, 255, 1),#3191FF );
                            /* Firefox 3.6 - 15 */
                            background: linear-gradient(to top, rgba(255, 255, 255, 1), #3191FF );
                            height: 20%;
                        }
                    }

                    .point {
                        position: relative;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        border: 1px solid #3191FF;
                        /*transform: translateY(-50%);*/
                        flex: 0 0 auto;
                        z-index: 99;
                        background-color: rgb(255, 255, 255);

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background-color: #3191FF ;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                        }
                    }
                }

                .right {
                    width: 100%;
                    /*padding-bottom: 15px;*/
                    /*margin-bottom: 15px;*/
                    margin-top: 15px;

                    .approve_panel {
                        margin: 0 15px;
                        border-radius: @redius;
                        background-color: rgb(255, 255, 255);
                        padding: 10px;

                        p {
                            padding-top: 10px;
                            font-weight: bolder;

                            &:last-of-type {
                                padding-bottom: 10px;
                            }

                            span {
                                font-weight: normal;
                                color: rgb(82, 82, 82);
                            }
                        }
                    }
                }
            }

        }
    }
    .divshow{
        position: absolute;
        top: 52px;
        width: 100%;
    }
    .selectWays{
        width: 100%;
        height: 35px;
        font-size: 14px;
        text-align: center;
        /*border-top: none;*/
        /*vertical-align: csenter;*/
        line-height: 35px;
        background-color: #f5f5f5;
        color: darkgrey;
    }
    .rule{
        width: 99%;
        border: 0.5px solid #e6e6e6;
    }
    .arrows{
        height: 0;
        width: 0;
        border:4px solid ;
        border-color: black transparent transparent transparent ;
        margin-left:10px;
        margin-top: 10px;
        /*margin-top: -8px;*/
       flex: 0;

    }
    .selecy-followway{
        /*top : 11px;*/
        color: darkgrey;
        /*position: relative;*/
        font-size: 15px;
        flex: 113;


    }
    .selecy-followwayBlack{
        font-size: 15px;
        color: black;
        flex: 113;
        /*position: relative;*/
        /*top : 14px;*/
    }
    .selectdevide{
        /*position: absolute;*/
        /*left: -60px;*/
        color: #f5f5f5;
        /*top:10px;*/
        font-size: 15px;
        /*flex: 3;*/
        margin-right: 10px;

    }
    .formDiv{
        background-color: #fafafa;
        /*height: 160px;*/
        width: 100%;
        margin-top:20px;
    }
    .firstLine{
        position: relative;
        background-color: white;
        height: 52px;
        width: 90%;
        margin: 11px auto 0;
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
    }
    .secondLine{
        background-color: white;
        /*height: 4.16rem;*/
        width: 90%;
        margin: 3px auto;
        border-radius: 0 0 8px 8px;
        font-size: 15px;
        padding-bottom: 15px;
        .followDetailTwo{
            display: flex;
            align-items: center;
            margin-left: 13px;
            height: 45px;
            /*margin-bottom: 15px;*/
        }
    }
    .circle1{
        height: 0.13333rem;
        width: 0.13333rem;
        background-color: #9d9d9d;
        border-radius: 100%;
        margin-left: 13px;
        padding-top: 0px;
        margin-top: 0px;
        margin-right: 10px;
    }
    .redcircle1{
        height: 5px;
        width: 5px;
        background-color: red;
        border-radius: 100%;
    }
    .fangshi{
        background-color: white;
        width: 70px;
        font-size: 15px;
        font-weight: bold;
    }
    .selectDiv{
        width: 220px;
        display: flex;
        align-items: center;
        font-size: 15px;
    }
    .circle2{
        width: 5px;
        height: 5px;
        background-color: #9d9d9d;
        border-radius: 100%;
        margin-right: 10px;
    }
    .redcircle2{
        width: 5px;
        height: 5px;
        background-color: red;
        border-radius: 100%;
        margin-right: 10px;
    }
    .neirong{
        background-color: white;
        font-size: 15px;
        font-weight: bold;
    }
    .savebutton{
        background:-webkit-linear-gradient(left,#f0cba1,#ee6949);
        border: none;
        border-radius: 0.13333rem;
        color: white;
        width: 90%;
        height: 45px;
       display: block;
        margin: 15px auto;
        font-size: 15px;
        font-weight: bold;
    }
    .savebuttongrew{
        background: darkgray;
        border: none;
        border-radius: 0.13333rem;
        color: white;
        width: 90%;
        height: 45px;
        margin: 15px auto;
        font-size: 15px;
        font-weight: bold;
        display: block;
    }
    .genjinSelect{
        color: #9d9d9d;
        font-size: medium;
        width: 180px;
        height: 35px;
        border:white;
        background-color: white;
    }

    .questionText{
        width: 100%;
        height: 100%;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;
        background-color:#fafafa;
        /*border-color: #fafafa;*/
        border-radius: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        resize: none;
        font-size: 14px;
        border: none;
    }
    .questionDiv{
        height: 100px;
        width: 300px;
        background-color: white;
        margin: 0 auto;
    }
    .followRecord{
        background-color: red;
        color: coral;
        background-color: #f8f8f8;
        font-size: 12px;
        margin:30px auto 0;
        width: 90%;

    }
    .genjinList{
        /*background-color: red;*/
        /*height: 3.84rem;*/
        /*width: 100%;*/
        /*!* background-color: #f8f8f8; *!*/
        /*background-color: #f5f5f5;*/

        /*border-radius: 0.13333rem;*/
        /*margin-top: 4.29333rem;*/
        /*margin-bottom: -4.46667rem;*/
        display: flex;
    }
    .ganjinxian{
        background-color: #77b2ff;
        width: 26px;
        height: 3.5rem;
        position: relative;
        left: 13px;
        float: left;
        top: -0.06667rem;
        /*background-image: url("src/assets/images/potentialHouse-icon.png");*/
    }
    .genjinOne{
        background-color: #ffffff;
        width: 316px;
        /*height: 3.5rem;*/
        float: left;
        left: 14px;
        border-radius:8px;
        top: -0.06667rem;


    }
    .followInfo{
        width: 316px;
        /*height: 72px;*/
        font-size: small;
        border-radius: 8px;
        /*position: relative;*/
        /*margin-top: 15px;*/
        margin-left: 10px;
        /*border-bottom: solid 1px red;*/
        .followInfoDiv{
            display: flex;
            align-items: center;
            height: 39px;
            margin-top: 5px;
            .followType{
                background-color: #daeefc;
                /*width: 30px;*/
                line-height: 16px;
                height: 14px;
                /*padding-top: 2.5px;*/
                border-radius: 7px;
                font-size: 10px;
                text-align: center;
                color: #7dacf1;
                font-weight: bold;
                padding: 2px 2px 2px 2px;
                word-break: break-word;
				max-width: 51px;
				overflow: hidden;white-space: nowrap;text-overflow:ellipsis;
            }
        }
		.name{
		    float: left;
			margin-left: 10px;
		    font-weight: bold;
		    font-size: 12px;
		}
		.time{
			position: absolute;
		    color: #9d9d9d;
		    font-size: 12px;
			right: 45px;
		}
        .followDetail{
            width:95%;
            font-size: 12px;
            color: #9d9d9d;
            margin-bottom: 10px;
            word-break: break-word;
            line-height: 23px;
        }
    }

    .commentButton{
        position: relative;
        top: 9px;
        width: 100px;
        height: 42px;
        left: 207px;
        border-radius: 10px;
        background-clip:padding-box,border-box;
        background-origin:padding-box,border-box;
        background-image:linear-gradient(135deg,white,white),linear-gradient(135deg,#f0cba1,#ee6949);
        border:1px transparent solid;
        font-size: medium;
        /*color: coral;*/
        background-color: white;
        outline:none;
    }
    .selectfont{
        font-size: 17px;
        color: darkgrey;
    }
    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }
    .saveDiv{
        position: absolute;
        width: 330px;
        left: 0.66667rem;
        top: 10.50667rem;

    }
    .addFollow{
        width: 100%;
    }

</style>

<script>
import {
  Cell,
  CellGroup,
  Button,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Icon,
  Field,
  Col,
  Row,
  Divider,
  NavBar, Picker, Popup
} from 'vant'
import {followUpSave, queryBaseData, followUpList} from "../../../getData/getData";
import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import rxNavBar from '../../../components/rongxun/rx-navBar/navBar';
import RxNavBar from "../../../components/rongxun/rx-navBar/navBar";
export default {
    components: {
        RxNavBar,
        [Button.name]: Button,
        [Cell.name]:Cell,
        [CellGroup.name]:CellGroup,
        [Collapse.name]:Collapse,
        [CollapseItem.name]:CollapseItem,
        [Step.name]:Step,
        [Steps.name]:Steps,
        [Icon.name]:Icon,
        [Field.name]:Field,
        [Col.name]:Col,
        [Row.name]:Row,
        [Divider.name]:Divider,
        [NavBar.name]:NavBar,
            rxNavBar,
      [Picker.name]: Picker,
      [Popup.name]: Popup
    },

data(){

    return {
        //跟进类型  0 是潜客，1是潜房，2是账单
        followUpType : '',
      waysIndex: '',
        follow : {
            followUpDetail : ''
        },
        selectFlag:false,
        optionsShow: false,
        followType: '',
        followContent: '',
        disabledFalg: false,
        wayinfo: {
            dictionaryTitle: '请选择'
        },
        waysArr: {
            dictionaryTitle:'',
            followUpId : ''
        },
        orders: []
    }
},
    created() {

    },
    mounted() {
        this.followUpType = this.$route.query.followUpType
        this.id = this.$route.query.id,
        this.init();
        this.initD();
    },
    methods: {
        //跟进记录
        init: function () {
            let that = this
            let initData = {}
            initData.followUpItem_id = that.id,
            initData.staff_id = getStaffId(),
            initData.followUpType = that.followUpType
            followUpList(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    if(response.data.data.followUpList.length>0){
                        that.orders = response.data.data.followUpList
                    }
                })
            })
        },
        add: function () {
            let that = this
            that.disabledFalg = true;
            let addData = {}
            addData.id = that.id
            addData.followUpSubType = this.wayinfo.followUpId
            addData.followUpDetail = that.followContent,
            addData.staff_id = getStaffId(),
            addData.followUpType = that.followUpType
            followUpSave(addData).then(function (response) {
                that.disabledFalg = false;
                responseUtil.dealResponse(that, response, () => {
                    that.init();
                    that.followContent = '';
                    that.followType = '';
                    responseUtil.alertMsg(that,'添加成功')
                })
            })
        },
        selectoneway: function (item,index) {
            this.optionsShow = this.optionsShow
            this.wayinfo.dictionaryTitle= item.dictionaryTitle
            this.wayinfo.followUpId=item.id
            this.waysIndex = index
            if (this.wayinfo.dictionaryTitle != null) {
                document.getElementById("circle").style.backgroundColor = "red";
                this.optionsShow = !this.optionsShow
                this.selectFlag = !this.selectFlag
            }
        },
      initD(){
        let that = this
        let initData = {}
        initData.dbName = ['followUpType']
        initData.fdName = []
        queryBaseData(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.waysArr = response.data.data.followUpType
            console.log(that.waysArr)
          })
        })
      },
        clickWayShow: function () {
            this.optionsShow = !this.optionsShow
        },
    }
}

</script>




